/**
 * A form button component that will prompt the user before submitting a form.
 * This can be e.g. used when making sure the user does not accidentally do a irreversible action
 *
 * Usage:
 *  <form>
 *      <button type="submit" class="btn btn-primary"  <!-- standard bootstrap stuff -->
 *              data-prompt="Are you 100% sure?"       <!-- set what text will be displayed on click -->
 *              data-prompt-class="btn-danger active"  <!-- set what classes will be set on click -->
 *          Delete
 *      </button>
 *  </form>
 */
class VerifyBtn extends HTMLButtonElement {
    constructor() {
        super();

        this.prompt = this.dataset['prompt'];
        this.promptClasses = this.dataset['promptClass'].split(' ');

        if (this.form === null) {
            return;
        }

        let prompted = false;

        this.addEventListener('click', ev => {
            if (prompted) {
                this.form.submit();
                return;
            }

            ev.preventDefault();

            // XXX: I'm not sure how iterators in js, ideally
            //  you can iterate in reverse order and delete
            //  as you go, but this is fine

            const to_delete = [];

            // Remove bootstrap button color
            for (const key of this.classList.values()) {
                if (key.indexOf('btn-') === 0) {
                    to_delete.push(key);
                }
            }

            for (const key of to_delete) {
                this.classList.remove(key);
            }

            // Add new attributes
            for (const cls of this.promptClasses) {
                this.classList.add(cls);
            }
            this.textContent = this.prompt;

            prompted = true;
        });
    }
}

window.customElements.define('verify-button', VerifyBtn, {extends: 'button'});