import * as List from "list.js";

/**
 * Converts given string to a value usuable as a class.
 *
 * @param { string } s - the string to modify
 * @return { string } lowercase and single word version of s
 */
function naiveSlugify(s) {
    return s.toLowerCase().replaceAll(' ', '-');
}

/**
 * Represents a table that can be sorted and filtered.
 *
 * @property { object[] } headers - Keys used for sorting the column headers
 */
class SortableTable extends HTMLTableElement {
    constructor() {
        super();
    }
    connectedCallback() {
        // XXX: Add class "list" to first tbody if no element with "list" exists
        if (this.querySelector(".list") === null) {
            const body = this.querySelector("tbody");
            body.classList.add("list");
        }

        this.headers = Array
            .from(this.querySelectorAll("thead tr th"))
            .map(e => {
                return {
                    // Don't use header for ordering if it has "skip" class
                    shouldOrderOn: !e.classList.contains('dont-order'),

                    // Convert to a unique string
                    orderKey: 'sort-key-' + naiveSlugify(e.innerText),

                    element: e,
                }
            });

        const options = {
            valueNames: this.headers.filter(h => h.shouldOrderOn).map(h => h.orderKey),
        };

        for (const row of this.querySelectorAll("tbody tr")) {
            for (let i = 0; i < row.children.length; i++) {
                const header = this.headers[i];
                if (header.shouldOrderOn) {
                    row.children.item(i).classList.add(header.orderKey);
                }
            }
        }

        this.headers
            .filter(h => h.shouldOrderOn)
            .forEach(h => {
                h.element.classList.add('sort');
                h.element.dataset['sort'] = h.orderKey;
            })

        // Create List.js object from this table.
        new List(this, options);

        // Optional: Custom sorting function
        // list.sortFunction = function (itemA, itemB, options) {
        //     return list.utils.naturalSort(
        //         itemA.values()[options.valueName],
        //         itemB.values()[options.valueName]
        //     )
        // }
    }
}

window.customElements.define('sortable-table', SortableTable, {extends: 'table'});
