class mmCard extends HTMLElement {
  constructor() {
    super();
    let shadow = this.attachShadow({mode: 'open'});
    
    const title = this.getAttribute('card-title');
    shadow.innerHTML = `
      <style>
        .card {
          position: relative;
          display: flex;
          flex-direction: column;
          min-width: 0;
          word-wrap: break-word;
          background-color: #fff;
          background-clip: border-box;
          border: 1px solid rgba(0, 0, 0, 0.125);
          border-radius: 0;
        }
        .card-header {
          padding: 0.75rem 1.25rem;
          margin-bottom: 0;
          background-color: #406E8E;
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);
          color: #ffffff;
        }
        /*
        For semantic header elements, while still maintaining original card header styles.
         */
        .card-header h1,
        .card-header h2 {
          margin-block-start: 0px;
          margin-block-end: 0px;
          font-size: 14px;
          font-weight: 400;
        }
        .card-header:first-child {
          border-radius: 0;
        }
        .card-body {
          flex: 1 1 auto;
          min-height: 1px;
          padding: 1.25rem;
        }
      </style>

      <div class="card">
        <div class="card-header">
          ${title}    
        </div>
        <div class="card-body">
          <slot></slot>
        </div>
      </div>           
    `    
  }
  connectedCallback() {
  }
}


window.customElements.define('mm-card', mmCard);