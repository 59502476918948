(function (document) {
    "use strict";

    const counterSpan = document.querySelector("#noSelected");
    const itemIdsInput = document.querySelector("#item-ids");

    function countCheckboxes() {
      // Count number selected and update counter
      let totalSelected = 0;
      let selectedItems = [];
      itemIdsInput.value = "";
      document.querySelectorAll(".check-select").forEach(item => {
        if (item.checked) {
          totalSelected += 1;
          let itemID = item.getAttribute("data-item-id");
          if(itemID){
            selectedItems.push(itemID);
          }
        }
      });
      itemIdsInput.value = selectedItems.join(",");
      return totalSelected
    }

    // Add event handlers
    document.querySelectorAll(".check-select-all").forEach(item => {
      item.addEventListener("change", event => {
        document.querySelectorAll(".check-select").forEach(item => {
          if (item.disabled !== true) {
            item.checked = event.target.checked;
          }
        });
        counterSpan.innerHTML = countCheckboxes();
      });
    });

    document.querySelectorAll(".check-select").forEach(item => {
      item.addEventListener("change", event => {
        counterSpan.innerHTML = countCheckboxes();
      });
    });
})(document);
