import $ from 'jquery'
import "bootstrap";
import * as Feather from "feather-icons";
import "flag-icon-css/sass/flag-icon.scss";

import "./scss/styles.scss";
import "./scss/linear-spinner.scss";

import "./components/table";
import "./components/card";
import "./components/verify-button";

import "./utils/custom-event-poly";
import "./utils/selector";

Feather.replace();

window.feather = Feather;

// Hack to make sure table rows do not go below the navbar when refered to in url hash
let shiftWindow = function() {
    setTimeout(function() {
        scrollBy(0, -80);
    }, 100);
};

document.addEventListener("DOMContentLoaded", function() {
  if (location.hash) shiftWindow();
});

window.addEventListener("hashchange", shiftWindow);

let clickableRows = document.querySelectorAll(".clickable tbody tr:not(.no-click)");
clickableRows.forEach((row) => {
  row.addEventListener("click", (e) => {
    if(!e.target.getAttribute("is") && !e.target.classList.contains("check-select")) {
      window.document.location = e.currentTarget.getAttribute("data-href");
    }
  });
});

/**
 * Add some logic for collapsables. State is stored in local storage.
 *
 * @type {NodeListOf<Element>}
 */
let collapseControls = document.querySelectorAll(".collapseControl");
collapseControls.forEach((collapseControl) => {
  let target = collapseControl.getAttribute("data-target");
  let collapsable = document.querySelector(target);

  // Add event listener to the button to toggle and to save state to local storage
  collapseControl.addEventListener("click", (e) => {
    $(collapsable).toggle();
    localStorage.setItem(target, collapsable.style.display === "block");
  });

  // read current state from local storage to determine wether the collapsable should be open or closed on page load
  let state = localStorage.getItem(target);
  if (state === null) {
    collapsable.style.display = "none"
  } else {
    let closed = state === "false";
    if (!closed) {
      collapsable.style.display = "block";
    } else {
      collapsable.style.display = "none";
    }
  }
})
